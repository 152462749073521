// textarea {
//     resize: none;
// }
// .practice-detail-sec {
// }
.practice-photos-sec {
    .clinic-img-sec {
        .img-box {
            text-align: center;
            margin-bottom: 30px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            img {
                width: 100%;
            }
            .hover-btns {
                background-color: rgba(51, 51, 51, 0.77);
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                visibility: hidden;
                -webkit-transition: all 0.2s;
                -o-transition: all 0.2s;
                transition: all 0.2s;
            }
            &:hover {
                .hover-btns {
                    visibility: visible;
                }
            }
        }
    }
}

.practice-contact-sec {
    .map-box {
        margin: -1rem -1rem 1rem -1rem;
    }
}

.custom-check-list {
    .form-check-input:disabled ~ .form-check-label,
    .form-check-input[disabled] ~ .form-check-label {
        opacity: 1;
    }
    .form-check {
        position: relative;
        z-index: 0;
        padding: 0;
        margin: 7px 14px 7px 0;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 15px;
            right: auto;
            margin: auto 0;
            &:focus {
                box-shadow: none !important;
            }
        }
        label {
            padding: 7px 15px 7px 40px;
        }
        input[type="checkbox"] {
            &:checked {
                + {
                    label {
                        border-color: #1676ee !important;
                        background-color: rgba(22, 118, 238, 0.05);
                        color: #1676ee;
                    }
                }
            }
        }
    }
}
.hidden-input {
    input[type="checkbox"] {
        display: none !important;
    }
    .form-check {
        label {
            padding: 7px 15px 7px 15px;
        }
    }
}

.payment-mode-checkbox {
    font-size: 14px;
}

.aptype-duration-sec {
    .advances-table {
        tr {
            td,
            th {
                padding: 3px 15px !important;
            }
        }
    }
}

.Provider-contact-sec,
.practice-contact-sec {
    .icon-box {
        min-width: 24px;
        text-align: center;
    }
}

// .clinic-navbar {
//     padding-top: 30px;
//     border: 1px solid #d8d8d8;
//     .nav-tabs {
//         border: 0;
//     }
//     .nav-link {
//         color: #9d9d9d;
//         border: 0;
//         margin: 0;
//         padding: 10px 0;
//         margin: 0 15px;
//         position: relative;
//     }
//     .nav-item {
//         &:first-child {
//             .nav-link {
//                 margin-left: 0;
//             }
//         }
//         &:last-child {
//             .nav-link {
//                 margin-right: 0;
//             }
//         }
//     }
//     .nav-link.active {
//         color: #000000;
//         font-weight: 500;
//         &::after {
//             content: "";
//             position: absolute;
//             width: 100%;
//             height: 3px;
//             background-color: #1676ee;
//             left: 0;
//             bottom: 0;
//         }
//     }
// }
@media only screen and (min-width: 1200px) and (max-width: 1320px) {
    .practice-contact-sec {
        ul {
            li {
                font-size: 15px;
                .btn-link {
                    font-size: 14px;
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hidden-input .form-check label {
        font-size: 13px;
        padding: 7px 10px;
    }
    .practice-contact-sec .map-box {
        margin: -1rem -0.5rem 1rem -0.5rem;
    }
    .practice-contact-sec {
        ul {
            li {
                font-size: 13px;
                .btn-link {
                    font-size: 13px;
                }
            }
        }
    }
    .practice-hours-sec
    {
        span
        {
            font-size: 13px;
        }
    }
    .Provider-contact-sec .icon-box,
    .practice-contact-sec .icon-box {
        margin-right: 7px !important;
    }
    .aptype-duration-sec .advances-table tr td
    {
        font-size: 13px;
        &:first-child
        {
           min-width: 150px;
        }
    }
}
