
// Avatar
//
// 
$avatar-bg:                        #999999 !default;                  
$avatar-size:                      40px !default;  
$avatar-size-sm:                   24px !default;
$avatar-size-lg:                   48px !default;
$avatar-size-xxl:                  100px !default;
$avatar-icon:                      16px !default;
$avatar-icon-sm:                   14px !default;
$avatar-icon-lg:                   24px !default;
$avatar-size-thumb:                190px !default;

.avatar {
    font-size: 14px;
    text-align: center;
    background: $avatar-bg;
    color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: $avatar-size;
    height: $avatar-size;
    line-height: $avatar-size;
    border-radius: 50%;
    display: inline-block;

    &.avatar-icon {
        font-size: $avatar-icon;
    }

    > img {
        display: block;
        width: 100%;
        height: 100%;
    }

    &.avatar-badge {
        overflow: visible;
        position: relative;

        &.avatar-square {

            .badge {
                top: -3px;
                right: -3px;
            }
        }

        .badge {
            border: 1px solid #fff;
            position: absolute;
            top: 0px;
            right: 0;

            &.badge-danger {
                background-color: #f5222d;
            }

            &:not(:empty) {
                top: -7px;
                right: -7px;
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-weight: 400
            }
        }
    }
    
}


.avatar-xxl {
    width: $avatar-size-xxl;
    height: $avatar-size-xxl;
    line-height: $avatar-size-xxl;

    > * {
        line-height: $avatar-size-xxl;
    }    

    &.avatar-icon {
        font-size: $avatar-icon-lg;
    }
}

.avatar-thumb {
    width: $avatar-size-thumb;
    height: $avatar-size-thumb;
    line-height: $avatar-size-thumb;

    > * {
        line-height: $avatar-size-thumb;
    }    

    &.avatar-icon {
        font-size: $avatar-icon-lg;
    }
}

.avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    line-height: $avatar-size-lg;

    > * {
        line-height: $avatar-size-lg;
    }    

    &.avatar-icon {
        font-size: $avatar-icon-lg;
    }
}

.avatar-sm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;
    line-height: $avatar-size-sm;

    > * {
        line-height: $avatar-size-sm;
    }    

    &.avatar-icon {
        font-size: $avatar-icon-sm;
    }
}

.avatar-square {
    border-radius: 4px;
}

    

.avatar-text {

    .avatar {
        margin-left: -5px;
        border: 2px solid #fff;
    }
} 

.avatar-string {

    .avatar {
        margin-left: -8px;
        border: 2px solid #fff;
    }
}