$chat-header-height: 60px;
$text-write-height: 70px;
$header-height: 70px;

.chatlist-panel {
	height: calc(100vh - 70px);
	overflow-x: hidden;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.chatbox-sc {
	height: calc(100vh - 200px);
	overflow-x: hidden;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	margin-top: $chat-header-height;
}

.chat-patient-detail-sc {
}
.chat-pdetail-cnt {
	height: calc(100vh - 200px);
	overflow-x: hidden;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	margin-top: $chat-header-height;
}

.chat-cnt-header {
	height: $chat-header-height;
}
.ch-write-sec {
	height: $text-write-height;
}
.chat-patient-data-header {
	height: $chat-header-height;
}

.chat-messages {
	display: flex;
	flex-direction: column;
}

.chat-message-left,
.chat-message-right {
	flex-shrink: 0;
	max-width: 85%;
	 img{ 
		max-width:100%;
	 }
}

.preview-image {
	position: absolute;
	right: 20px;
	bottom: 72px;  
	img {
		border-radius: 10px;
		margin-left: 5px;
	}
	i{
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 24px;
		line-height: 24px;
		cursor: pointer;
	}
}
.chat-message-left {
	margin-right: auto;
	.chatbu {
		background: #f5f5f5;
	}
}

.chat-message-right {
	flex-direction: row-reverse;
	position: relative;
	margin-left: auto;
	.chatbu {
		background: #e7f1fe;
	}
	.MuiAvatar-root.MuiAvatar-circle {
		position: absolute;
		font-size: 13px;
		width: 30px;
		height: 30px;
		left: -36px;
	}
}
#userListBox .list-group-item.active {
	background-color: #bfd9f4 !important;
	color: #212529 !important;
}
.chat-message-left {
	.chatbu.bg-primary {
		background-color: #bfd9f4 !important;
		color: #212529 !important;
	}
}
.chat-tab-links {
	padding: 15px 0 0 0;
	.nav-tabs .nav-link {
		border: none;
		background-color: none !important;
	}
	.nav-link {
		color: #9d9d9d;
		border: 0;
		margin: 0;
		padding: 0 0 10px 0;
		margin: 0 15px;
		position: relative;
	}
	.nav-link.active {
		color: #333333;
		font-weight: 500;
		border: none;
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 3px;
			background-color: #0267d4;
			left: 0;
			bottom: 0;
		}
	}
}
