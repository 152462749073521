.billing_status  {
    .modal-dialog {
        max-width: 800px;
    }
}
.custom-click {
	width: 100%;
	height: 100%;
	padding: 20px 15px;
	background-color: #fff;
	color: #666;
	transition: all .2s;
	border: 2px solid #e5e5e5;
	position: relative;
	box-shadow: 2px 2px 5px rgba(0,0,0,.05);
    border-radius: 6px;
    .img-container {
        margin: 0 0 10px 0;
        i {
            font-size: 24px;
        }
    }
}

.billing_s_act {
    .col-sm-4 {
        margin: 10px 0;
    } 
}
.billing_status {
    .modal-header {
        padding: 1rem 30px;
        .modal-title {
            width: 100%;
            text-align: center;
        }
    }
    .modal-body {
        padding: 40px 60px;
    }
}